/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --fontSize: 14px;
  --transition: 0.5s;
  --mainColor: #ed2775;
  --whiteColor: #ffffff;
  --blackColor: #262a37;
  --gradientColor: linear-gradient(44.44deg, #ed2775 7.79%, #ff7448 94.18%);
  --paragraphColor: #716c80;
  --fontFamily: "Inter", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  font: {
    size: var(--fontSize);
    family: var(--fontFamily);
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--blackColor);
  font-weight: 700;
}
a {
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    color: var(--mainColor);
    text-decoration: none;
  }
}
:focus {
  outline: 0 !important;
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
img {
  max-width: 100%;
  height: auto;
}
p {
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;

  &:last-child {
    margin-bottom: 0;
  }
}
.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.ptb-75 {
  padding: {
    top: 75px;
    bottom: 75px;
  }
}
.pt-75 {
  padding-top: 75px;
}
.pb-75 {
  padding-bottom: 75px;
}
.bg-F7F7FF {
  background-color: #f7f7ff;
}
.bg-F4F8FC {
  background-color: #f4f8fc;
}
.bg-f9f9f9 {
  background-color: #f9f9f9;
}

/*default-btn*/
.default-btn {
  z-index: 1;
  border: none;
  position: relative;
  padding: 12px 35px;
  border-radius: 30px;
  display: inline-block;
  color: var(--whiteColor);
  transition: var(--transition);
  box-shadow: 0px 12px 35px rgba(237, 39, 117, 0.25);
  font: {
    size: var(--fontSize);
    weight: 600;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    transition: var(--transition);
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 30px;
    background: var(--gradientColor);
  }
  &:hover {
    background-color: var(--mainColor);
    color: var(--whiteColor);

    &::before {
      opacity: 0;
      visibility: hidden;
    }
  }
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 660px;
  margin: {
    left: auto;
    right: auto;
    bottom: 55px;
  }
  .sub-title {
    display: block;
    margin-bottom: 10px;
    background: var(--gradientColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.1em;
    font-weight: 600;
  }
  h2 {
    line-height: 1.4;
    margin-bottom: 0;
    font-size: 36px;
  }
  p {
    font-size: 16px;
    margin-top: 10px;
  }
  &.color-white {
    .sub-title {
      color: var(--whiteColor);
      background: transparent;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
    }
    h2 {
      color: var(--whiteColor);
    }
  }
  &.title-with-bg-text {
    position: relative;
    z-index: 1;

    .big-title {
      font-size: 100px;
      z-index: -1;
      font-weight: 800;
      opacity: 0.06;
      background: var(--gradientColor);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      position: absolute;
      top: -50px;
      right: 0;
      left: 0;
    }
  }
}

/*form-control*/
.form-control {
  background-color: #f5f5f5 !important;
  box-shadow: unset !important;
  transition: var(--transition);
  font-size: var(--fontSize);
  color: var(--blackColor);
  border: none !important;
  padding-left: 15px;
  border-radius: 0;
  height: 50px;

  &::placeholder {
    color: var(--paragraphColor);
    transition: var(--transition);
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}
textarea.form-control {
  padding-top: 15px;
  height: auto;
}

/*shape-css*/
.shape1 {
  bottom: 50px;
  z-index: -1;
  left: -50px;
  position: absolute;
  animation: movebounce 5s linear infinite;
}
.shape2 {
  bottom: 18%;
  z-index: -1;
  right: -70px;
  position: absolute;
  animation: movebounce 5s linear infinite;
}
.bg-shape1 {
  left: 0;
  right: 0;
  top: 50%;
  z-index: -1;
  position: absolute;
  transform: translateY(-50%);
}
.shape3 {
  right: 40px;
  bottom: 50px;
  z-index: -1;
  position: absolute;
  animation: movebounce 5s linear infinite;
}
.shape4 {
  top: 90px;
  left: 70px;
  z-index: -1;
  position: absolute;
  animation: movebounce 5s linear infinite;
}
.shape5 {
  left: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
}
.shape6 {
  position: absolute;
  z-index: -1;
  right: 10%;
  bottom: 8%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.shape7 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 4%;

  img {
    animation: opacitychange 4s linear 1s infinite;
  }
}
.shape8 {
  position: absolute;
  z-index: -1;
  bottom: 28%;
  left: 30%;

  img {
    animation: {
      name: rotateme;
      duration: 20s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape9 {
  position: absolute;
  z-index: -1;
  bottom: 5px;
  left: 5px;

  img {
    animation: opacitychange 4s linear 1s infinite;
  }
}
.shape10 {
  position: absolute;
  bottom: 40px;
  left: -40px;
  z-index: -1;
}
.shape11 {
  position: absolute;
  right: -30px;
  z-index: -1;
  top: 80px;
}
.shape12 {
  position: absolute;
  z-index: -1;
  bottom: 12%;
  left: 30%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.shape13 {
  position: absolute;
  z-index: -1;
  right: 18%;
  top: 18%;

  img {
    animation: {
      name: rotateme;
      duration: 20s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.lines {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  margin: auto;
  position: absolute;

  .line {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    background: rgba(255, 255, 255, 0.1);

    &::after {
      left: 0;
      top: -50%;
      width: 100%;
      content: "";
      height: 15vh;
      display: block;
      position: absolute;
      animation: run 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        var(--whiteColor) 75%,
        var(--whiteColor) 100%
      );
    }
    &:nth-child(1) {
      margin-left: -35%;

      &::after {
        animation-delay: 2s;
      }
    }
    &:nth-child(3) {
      margin-left: -17.5%;

      &::after {
        animation-delay: 2.5s;
      }
    }
    &:nth-child(4) {
      margin-left: 17.5%;

      &::after {
        animation-delay: 3s;
      }
    }
    &:nth-child(5) {
      margin-left: 35%;

      &::after {
        animation-delay: 3.5s;
      }
    }
  }
}
.shape14 {
  position: absolute;
  z-index: -1;
  right: 15%;
  top: 30%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.shape15 {
  position: absolute;
  bottom: 10px;
  z-index: -2;
  right: 50%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.divider {
  width: 100%;
  height: 100px;
  position: absolute;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  background: var(--whiteColor);
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  z-index: -1;
  bottom: 0;
  left: 0;
}
.banner-shape1 {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape2 {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 20%;

  img {
    animation: {
      name: rotateme;
      duration: 40s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.banner-shape3 {
  position: absolute;
  bottom: 35%;
  z-index: -1;
  left: 55%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape4 {
  position: absolute;
  z-index: -1;
  bottom: 50%;
  right: 5%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape5 {
  position: absolute;
  bottom: -30px;
  z-index: -1;
  right: 12.5%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape6 {
  position: absolute;
  z-index: -1;
  right: 43%;
  top: 32%;

  img {
    animation: {
      name: rotateme;
      duration: 20s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.banner-shape7 {
  position: absolute;
  z-index: -1;
  bottom: 15%;
  right: 90px;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape8 {
  position: absolute;
  z-index: -1;
  bottom: 15%;
  right: 34%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.banner-shape9 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 45%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.banner-shape10 {
  position: absolute;
  bottom: 10px;
  z-index: -1;
  right: 1%;

  img {
    animation: opacitychange 4s linear 1s infinite;
  }
}
.banner-shape11 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
}

/*page-title*/
.page-title-area {
  z-index: 1;
  position: relative;
  background: linear-gradient(50.48deg, #ed2775 -2.06%, #ff7448 93.55%);
  padding: {
    bottom: 100px;
    top: 180px;
  }
  &::before {
    left: 0;
    right: 0;
    top: 82px;
    height: 1px;
    content: "";
    z-index: -1;
    opacity: 0.2;
    position: absolute;
    background-color: var(--whiteColor);
  }
  &.page-title-style-two {
    background: #eef5fc;

    &::before {
      background-color: var(--blackColor);
      opacity: 0.04;
    }
    .page-title-content {
      h2 {
        color: var(--blackColor);
      }
      ul {
        li {
          color: var(--blackColor);

          a {
            color: var(--mainColor);

            &:hover {
              color: var(--blackColor);
            }
          }
          &::before {
            background-color: var(--blackColor);
            opacity: 0.3;
          }
        }
      }
    }
  }
  &.bg-color {
    padding-bottom: 90px;
    background: linear-gradient(
      76.8deg,
      #2ba6ac 0%,
      #36ce83 53.03%,
      #42e695 100%
    );

    &::before {
      display: none;
    }
    .divider {
      display: none;
    }
  }
}
.page-title-content {
  text-align: center;

  h2 {
    color: var(--whiteColor);
    margin-bottom: 0;
    font-size: 40px;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 15px;
    }
    li {
      color: var(--whiteColor);
      display: inline-block;
      position: relative;
      font-size: 16px;
      opacity: 0.9;
      margin: {
        left: 16px;
        right: 16px;
      }
      &:last-child {
        margin-right: 0;

        &::before {
          display: none;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        color: var(--whiteColor);
      }
      &::before {
        margin-top: 1px;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: var(--whiteColor);
        border-radius: 50%;
      }
    }
  }
}

/*products-details-tabs*/
.products-details-tabs {
  .nav-tabset {
    border-bottom: 1px solid #dee2e6;
    list-style-type: none;
    margin-bottom: 30px;
    padding-left: 0;

    .nav-tab {
      display: inline-block;
      margin: {
        left: 18px;
        right: 18px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      span {
        padding: 0;
        border: none;
        display: block;
        cursor: pointer;
        color: #cccccc;
        position: relative;
        padding-bottom: 8px;
        background-color: transparent;
        transition: var(--transition);
        font: {
          size: 18px;
          weight: 600;
        }
        &::before {
          left: 0;
          width: 0;
          content: "";
          height: 3px;
          bottom: -2px;
          position: absolute;
          transition: var(--transition);
          background-color: var(--mainColor);
        }
        &:hover {
          color: var(--blackColor);

          &::before {
            width: 100%;
          }
        }
      }
      &.active {
        span {
          color: var(--blackColor);

          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}

/*pagination*/
.pagination-area {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px solid #eeeeee;

  .nav-links {
    display: flex;
    align-items: center;
  }
  .page-numbers {
    width: 45px;
    height: 45px;
    border: none;
    display: block;
    line-height: 43px;
    position: relative;
    text-align: center;
    border-radius: 50%;
    color: var(--paragraphColor);
    transition: var(--transition);
    background: linear-gradient(180deg, #ffffff 0%, #eaeef3 100%);
    box-shadow: 0 5px 15px 0 rgba(107, 124, 147, 0.15),
      0 2px 4px 0 rgba(107, 124, 147, 0.2), inset 0 -2px 0 0 #c2cfde;
    font: {
      size: 15px;
      weight: 700;
    }
    margin: {
      left: 5px;
      right: 5px;
    }
    &.next {
      margin-left: auto;
    }
    &:hover {
      color: var(--mainColor);
    }
    &.current {
      background: var(--whiteColor);
      color: var(--mainColor);
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

/*keyframes-css*/
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes opacitychange {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/*owl-carousel-css*/
.owl-item {
  &.active {
    &.center {
      .single-feedback-box {
        background-color: var(--whiteColor);
        box-shadow: 5px 15px 20px rgba(38, 42, 55, 0.05);
      }
    }
  }
}
.feedback-slides {
  .owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 20px;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          border-radius: 50%;
          position: relative;
          background-color: transparent;

          &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            opacity: 0.1;
            position: absolute;
            border-radius: 50%;
            background-color: #0064fb;
            transition: var(--transition);
          }
          &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            background: linear-gradient(
              279.99deg,
              #9f5ff1 -1.19%,
              #ff54b0 50.99%,
              #ff9f5a 100%
            );
          }
        }
        &:hover,
        &.active {
          span {
            &::before {
              opacity: 0;
              visibility: hidden;
            }
            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.screenshots-slides {
  .owl-theme {
    .owl-nav {
      opacity: 0;
      margin-top: 0;
      visibility: hidden;
      transition: var(--transition);

      [class*="owl-"] {
        position: absolute;
        top: -60px;
        left: 0;
        font-size: 25px;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
        background-color: #f1f1f1;
        color: var(--mainColor);
        margin: 0;
        line-height: 42px;
        transition: var(--transition);
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
          top: 0;
          bottom: 0;
          z-index: -1;
          background: var(--gradientColor);
          transition: var(--transition);
          opacity: 0;
          visibility: hidden;
        }
        &.owl-next {
          left: auto;
          right: 0;
        }
        &:hover {
          background-color: #f9f9f9;
          color: var(--whiteColor);
          border-radius: 50%;

          &::before {
            opacity: 1;
            border-radius: 50%;
            visibility: visible;
          }
        }
      }
    }
    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.screenshots-swiper-slides {
  .swiper-slide {
    img {
      transform: scale(0.95);
      transition: var(--transition);
    }
  }
  .owl-theme {
    .owl-nav {
      opacity: 0;
      margin-top: 0;
      visibility: visible;
      transition: var(--transition);

      [class*="owl-"] {
        left: 15px;
        top: 50%;
        margin: 0;
        padding: 0;
        z-index: 1;
        width: 45px;
        height: 45px;
        font-size: 30px;
        position: absolute;
        border-radius: 50%;
        color: var(--whiteColor);
        transform: translateY(-50%);
        box-shadow: 0px 12px 35px rgba(237, 39, 117, 0.25);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          z-index: -1;
          background: var(--gradientColor);
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 50%;
        }
        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &.owl-next {
          left: auto;
          right: 15px;
        }
      }
    }
    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .owl-item {
    &.center {
      .swiper-slide {
        img {
          transform: scale(1);
          box-shadow: 0px 35px 70px 5px rgba(25, 34, 64, 0.15);
        }
      }
    }
  }
  .owl-stage-outer {
    overflow: hidden;
    padding: {
      top: 100px;
      bottom: 100px;
    }
    margin: {
      top: -100px;
      bottom: -100px;
    }
  }
}
.feedback-swiper-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        top: 50%;
        margin: 0;
        padding: 0;
        z-index: 1;
        left: -80px;
        width: 45px;
        height: 45px;
        font-size: 30px;
        position: absolute;
        border-radius: 50%;
        color: var(--mainColor);
        transform: translateY(-50%);
        background-color: #f9f9f9;
        transition: var(--transition);

        &::before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          content: "";
          z-index: -1;
          border-radius: 50%;
          position: absolute;
          visibility: hidden;
          transition: var(--transition);
          background: var(--gradientColor);
        }
        i {
          left: 0;
          right: 0;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
        }
        &.owl-next {
          left: auto;
          right: -80px;
        }
        &:hover {
          color: var(--whiteColor);
          box-shadow: 0px 12px 35px rgba(237, 39, 117, 0.25);

          &::before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.feedback-slides-two {
  .owl-theme {
    .single-feedback-box {
      background-color: var(--whiteColor);
      box-shadow: unset;
    }
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 20px;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          border-radius: 50%;
          position: relative;
          background-color: transparent;

          &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            opacity: 0.1;
            position: absolute;
            border-radius: 50%;
            background-color: #0064fb;
            transition: var(--transition);
          }
          &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            background: linear-gradient(
              279.99deg,
              #9f5ff1 -1.19%,
              #ff54b0 50.99%,
              #ff9f5a 100%
            );
          }
        }
        &:hover,
        &.active {
          span {
            &::before {
              opacity: 0;
              visibility: hidden;
            }
            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.screen-swiper-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        top: 50%;
        margin: 0;
        padding: 0;
        z-index: 1;
        width: 45px;
        left: -100px;
        height: 45px;
        font-size: 30px;
        position: absolute;
        border-radius: 50%;
        color: var(--whiteColor);
        transform: translateY(-50%);
        background-color: #393f50;
        transition: var(--transition);

        &::before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          content: "";
          z-index: -1;
          border-radius: 50%;
          position: absolute;
          visibility: hidden;
          transition: var(--transition);
          background: var(--gradientColor);
        }
        i {
          left: 0;
          right: 0;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
        }
        &.owl-next {
          left: auto;
          right: -100px;
        }
        &:hover {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.feedback-tab-slides {
  .owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 40px;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          border-radius: 50%;
          position: relative;
          background-color: transparent;

          &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            opacity: 0.1;
            position: absolute;
            border-radius: 50%;
            background-color: #0064fb;
            transition: var(--transition);
          }
          &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            background: linear-gradient(
              279.99deg,
              #9f5ff1 -1.19%,
              #ff54b0 50.99%,
              #ff9f5a 100%
            );
          }
        }
        &:hover,
        &.active {
          span {
            &::before {
              opacity: 0;
              visibility: hidden;
            }
            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.testimonials-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 20px;
      text-align: start;

      [class*="owl-"] {
        position: relative;
        font-size: 25px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f1f1f1;
        color: var(--mainColor);
        margin: 0;
        padding: 0;
        line-height: 42px;
        transition: var(--transition);
        z-index: 1;
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          z-index: -1;
          background: var(--gradientColor);
          transition: var(--transition);
          opacity: 0;
          visibility: hidden;
        }
        &.owl-next {
          margin-left: 10px;
        }
        &:hover {
          background-color: #f9f9f9;
          color: var(--whiteColor);
          border-radius: 50%;

          &::before {
            opacity: 1;
            border-radius: 50%;
            visibility: visible;
          }
        }
      }
    }
  }
}
.app-screenshots-slides {
  .owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 30px;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          border-radius: 50%;
          position: relative;
          background: transparent;

          &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            opacity: 0.5;
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(
              44.44deg,
              #ed2775 7.79%,
              #ff7448 94.18%
            );
            transition: var(--transition);
          }
          &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            background: linear-gradient(
              279.99deg,
              #9f5ff1 -1.19%,
              #ff54b0 50.99%,
              #ff9f5a 100%
            );
          }
        }
        &:hover,
        &.active {
          span {
            &::before {
              opacity: 0;
              visibility: hidden;
            }
            &::after {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
.new-feedback-wrap-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        top: 42%;
        margin: 0;
        z-index: 1;
        padding: 0;
        left: 26.5%;
        width: 40px;
        height: 40px;
        font-size: 25px;
        line-height: 42px;
        position: absolute;
        border-radius: 50px;
        color: var(--whiteColor);
        transform: translateX(-26.5%);
        transition: var(--transition);
        background: linear-gradient(247.32deg, #4400b2 0%, #8b4feb 70.53%);

        &::before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          content: "";
          z-index: -1;
          position: absolute;
          visibility: hidden;
          border-radius: 50px;
          transition: var(--transition);
          background: var(--gradientColor);
        }
        &.owl-next {
          left: auto;
          right: 25.5%;
          transform: translateX(-25.5%);
        }
        &:hover {
          background-color: #f9f9f9;
          color: var(--whiteColor);
          border-radius: 50%;

          &::before {
            opacity: 1;
            border-radius: 50%;
            visibility: visible;
          }
        }
      }
    }
  }
}
.new-feedback-slides {
  .owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 5px;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          border-radius: 50%;
          position: relative;
          background-color: transparent;

          &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(88.83deg, #ed2775 0%, #ff7448 100%);
            opacity: 0.5;
            transition: var(--transition);
          }
          &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            content: "";
            position: absolute;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            background: linear-gradient(88.83deg, #ed2775 0%, #ff7448 100%);
          }
        }
        &:hover,
        &.active {
          span {
            &::before {
              opacity: 0;
              visibility: hidden;
            }
            &::after {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

/*extra-css*/
.pricing-area {
  position: relative;
  z-index: 1;

  &.bg-gradient-color {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      z-index: -1;
      position: absolute;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      background: linear-gradient(
        279.99deg,
        #9f5ff1 -1.19%,
        #ff54b0 50.99%,
        #ff9f5a 100%
      );
    }
  }
  &.gradient-color {
    background: linear-gradient(
      76.8deg,
      #2ba6ac 0%,
      #36ce83 53.03%,
      #42e695 100%
    );
  }
}
.screenshots-area {
  &.bg-color {
    position: relative;
    z-index: 1;

    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      z-index: -1;
      height: 55%;
      position: absolute;
      background-color: var(--blackColor);
    }
  }
}
.features-area {
  position: relative;
  z-index: 1;
}
.video-popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .popup-inner {
    width: 720px;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    background-color: var(--whiteColor);
    margin: {
      left: auto;
      right: auto;
    }
    iframe {
      width: 100%;
      height: 426px;
      border-radius: 5px;
    }
    .close-btn {
      top: -15px;
      width: 35px;
      right: -10px;
      border: none;
      height: 35px;
      font-size: 20px;
      line-height: 32px;
      border-radius: 50%;
      position: absolute;
      transition: var(--transition);
      background-color: var(--whiteColor);

      &:hover {
        background-color: red;
        color: var(--whiteColor);
      }
    }
  }
}
.partner-title {
  text-align: center;
  margin-bottom: 40px;
  font: {
    size: 17px;
    weight: 500;
  }
}
.feedback-area {
  &.bg-F4F8FC {
    .single-feedback-box {
      background-color: var(--whiteColor);
    }
  }
}
.screenshots-tabs {
  .nav-tabset {
    padding-left: 0;
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 1px solid #444444;

    .nav-tab {
      display: inline-block;
      margin: {
        left: 5px;
        right: 5px;
      }
      span {
        border: 1px solid transparent;
        transition: var(--transition);
        color: var(--whiteColor);
        position: relative;
        cursor: pointer;
        display: block;
        border: {
          top: {
            left-radius: 0.25rem;
            right-radius: 0.25rem;
          }
        }
        font: {
          size: 16px;
          weight: 600;
        }
        padding: {
          top: 10px;
          left: 25px;
          right: 25px;
          bottom: 12px;
        }
        i {
          transform: translateY(-50%);
          position: absolute;
          margin-top: -1px;
          left: 25px;
          top: 50%;
        }
        &:hover {
          border-color: #444444;
        }
      }
      &.active {
        span {
          background: var(--gradientColor);
          border-color: transparent;
          color: var(--whiteColor);
        }
      }
    }
  }
}
.feedback-wrap-area {
  background: #f6f9fb;

  .single-feedback-item {
    background-color: var(--whiteColor) !important;
  }
  .feedback-swiper-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          background-color: var(--whiteColor) !important;
        }
      }
    }
  }
}
.app-screenshots-wrap-area {
  background: {
    image: url(assets/img/more-home/screenshots/screenshots-bg.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  .section-title {
    .sub-title {
      background: var(--whiteColor);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    h2 {
      color: var(--whiteColor);
    }
  }
}

/*ngx-top*/
.scrolltop-button {
  transition: var(--transition) !important;
  background: var(--gradientColor) !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .ptb-100 {
    padding: {
      top: 60px;
      bottom: 60px;
    }
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-75 {
    padding: {
      top: 35px;
      bottom: 35px;
    }
  }
  .pt-75 {
    padding-top: 35px;
  }
  .pb-75 {
    padding-bottom: 35px;
  }

  .default-btn {
    padding: 11px 30px;
    font-size: 12.5px;
  }

  .shape1,
  .shape2,
  .bg-shape1,
  .shape6,
  .shape8,
  .shape12,
  .shape13,
  .shape10,
  .shape11 {
    display: none;
  }
  .shape7 {
    left: 0;
    width: 80px;
  }
  .banner-shape2,
  .banner-shape4,
  .banner-shape3 {
    display: none;
  }
  .banner-shape5 {
    text-align: center;
    right: 0;
    left: 0;
    margin: {
      left: auto;
      right: auto;
    }
  }
  .banner-shape6,
  .banner-shape7,
  .banner-shape8 {
    display: none;
  }
  .shape13,
  .shape14,
  .shape15 {
    display: none;
  }
  .banner-shape9 {
    display: none;
  }

  .section-title {
    margin-bottom: 40px;
    max-width: 100%;

    .sub-title {
      font-size: 12px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
    }
    &.title-with-bg-text {
      .big-title {
        font-size: 35px;
        top: -8px;
      }
    }
  }

  .page-title-area {
    padding: {
      top: 140px;
      bottom: 70px;
    }
    &::before {
      display: none;
    }
    .banner-shape1 {
      width: 80px;
    }
    &.bg-color {
      padding-bottom: 60px;
    }
  }
  .page-title-content {
    h2 {
      font-size: 22px;
    }
    ul {
      li {
        font-size: 13px;
      }
    }
  }
  .divider {
    height: 50px;
  }

  .form-control {
    font-size: 13px;
    height: 45px;
  }

  .pagination-area {
    margin-top: 0;
    padding-top: 20px;

    .page-numbers {
      width: 40px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }
  }

  /*owl-carousel-css*/
  .owl-item {
    &.active {
      &.center {
        .single-feedback-box {
          background: #f4f8fc;
          box-shadow: unset;
        }
      }
    }
  }
  .feedback-slides {
    .owl-theme {
      .owl-nav {
        &.disabled + .owl-dots {
          margin-top: 0;
        }
      }
    }
  }
  .screenshots-slides {
    .owl-theme {
      .owl-nav {
        opacity: 1;
        margin-top: 25px;
        visibility: visible;

        [class*="owl-"] {
          top: 0;
          width: 40px;
          height: 40px;
          font-size: 25px;
          position: relative;
          margin: {
            left: 5px;
            right: 5px;
          }
          &.owl-next {
            left: 0;
          }
        }
      }
    }
  }
  .screenshots-swiper-slides {
    .owl-stage-outer {
      padding: 0;
      margin: 0;
    }
    .owl-theme {
      .owl-nav {
        opacity: 1;
        margin-top: 20px;
        visibility: visible;

        [class*="owl-"] {
          left: 0;
          top: auto;
          width: 35px;
          height: 35px;
          font-size: 30px;
          transform: unset;
          position: relative;
          margin: {
            left: 4px;
            right: 4px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  .feedback-swiper-slides {
    .owl-theme {
      .owl-nav {
        margin-top: 20px;

        [class*="owl-"] {
          top: 0;
          left: 0;
          width: 35px;
          height: 35px;
          font-size: 30px;
          transform: unset;
          position: relative;
          margin: {
            left: 3px;
            right: 3px;
          }
          &.owl-next {
            right: 0;
          }
        }
      }
    }
  }
  .feedback-slides-two {
    .owl-theme {
      .owl-nav {
        &.disabled + .owl-dots {
          margin-top: 5px;
        }
      }
      .owl-dots {
        .owl-dot {
          span {
            &::after {
              background: var(--whiteColor);
            }
          }
        }
      }
    }
  }
  .screen-swiper-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          width: 30px;
          left: -40px;
          height: 30px;
          font-size: 20px;

          &.owl-next {
            left: auto;
            right: -40px;
          }
        }
      }
    }
  }
  .feedback-tab-slides {
    .owl-theme {
      .owl-nav {
        &.disabled + .owl-dots {
          margin-top: 25px;
        }
      }
    }
  }
  .testimonials-slides {
    .owl-theme {
      .owl-nav {
        text-align: center;

        [class*="owl-"] {
          width: 35px;
          height: 35px;
          font-size: 20px;
          line-height: 35px;
          margin: {
            left: 3px !important;
            right: 3px !important;
          }
        }
      }
    }
  }

  /*extra-css*/
  .video-popup {
    .popup-inner {
      width: 300px;
      padding: 10px;

      iframe {
        width: 100%;
        height: 177px;
      }
      .close-btn {
        width: 30px;
        right: -5px;
        height: 30px;
        font-size: 18px;
        line-height: 31px;
      }
    }
  }
  .partner-title {
    margin-bottom: 30px;
    font-size: 13px;
  }
  .screenshots-tabs {
    .nav-tabset {
      padding-bottom: 15px;

      .nav-tab {
        margin-bottom: 15px;

        span {
          font-size: 13px;
          margin-bottom: 0;
          border-radius: 3px;
          background-color: rgba(255, 255, 255, 0.1);
          padding: {
            top: 8px;
            left: 15px;
            right: 15px;
            bottom: 8px;
          }
        }
      }
    }
  }

  /*products-details-tabs*/
  .products-details-tabs {
    .nav {
      margin-bottom: 25px;

      .nav-item {
        margin: {
          left: 10px;
          right: 10px;
        }
        .nav-link {
          font-size: 15px;
        }
      }
    }
    .nav-tabset {
      margin-bottom: 25px;

      .nav-tab {
        margin: {
          left: 10px;
          right: 10px;
        }
        .nav-link {
          font-size: 15px;
        }
        span {
          font-size: 15px;
        }
      }
    }
  }

  .new-feedback-wrap-slides {
    .owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          top: unset;
          bottom: 0;
          transform: unset;
          left: 0;
          transform: unset;
          margin: 0 10px;

          &.owl-next {
            left: auto;
            right: 0;
            transform: unset;
          }
        }
      }
    }
  }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container-fluid {
    max-width: 540px;
  }

  /*extra-css*/
  .video-popup {
    .popup-inner {
      width: 400px;

      iframe {
        width: 100%;
        height: 238px;
      }
      .close-btn {
        right: -10px;
      }
    }
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-75 {
    padding: {
      top: 55px;
      bottom: 55px;
    }
  }
  .pt-75 {
    padding-top: 55px;
  }
  .pb-75 {
    padding-bottom: 55px;
  }

  .shape1,
  .shape2,
  .shape6 {
    display: none;
  }
  .shape10 {
    left: -20px;
  }
  .shape11 {
    right: -20px;
  }
  .banner-shape2,
  .banner-shape4,
  .banner-shape3 {
    display: none;
  }
  .banner-shape5 {
    left: 0;
    right: 0;
    text-align: center;
    margin: {
      left: auto;
      right: auto;
    }
  }
  .banner-shape6,
  .banner-shape7,
  .banner-shape8 {
    display: none;
  }
  .shape13,
  .shape14 {
    display: none;
  }

  .default-btn {
    font-size: 13px;
  }

  .section-title {
    max-width: 100%;
    margin-bottom: 50px;

    .sub-title {
      font-size: 13px;
    }
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
    &.title-with-bg-text {
      .big-title {
        font-size: 70px;
        top: -18px;
      }
    }
  }

  .page-title-area {
    padding: {
      top: 150px;
      bottom: 80px;
    }
    &::before {
      display: none;
    }
    .banner-shape1 {
      width: 100px;
    }
    .bg-color {
      padding-bottom: 80px;
    }
  }
  .page-title-content {
    h2 {
      font-size: 30px;
    }
    ul {
      margin-top: 10px;

      li {
        font-size: 14px;
      }
    }
  }
  .divider {
    height: 50px;
  }

  .pagination-area {
    margin-top: 10px;
    padding-top: 20px;
  }

  /*owl-carousel-css*/
  .screenshots-slides {
    .owl-theme {
      .owl-nav {
        opacity: 1;
        margin-top: 30px;
        visibility: visible;

        [class*="owl-"] {
          position: relative;
          top: 0;
          margin: {
            left: 5px;
            right: 5px;
          }
          &.owl-next {
            left: 0;
          }
        }
      }
    }
  }
  .screenshots-swiper-slides {
    .owl-theme {
      .owl-nav {
        opacity: 1;
        visibility: visible;

        [class*="owl-"] {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .feedback-swiper-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          width: 40px;
          height: 40px;
          left: -60px;

          &.owl-next {
            right: -60px;
          }
        }
      }
    }
  }
  .feedback-slides-two {
    &.owl-theme {
      .owl-nav {
        &.disabled + .owl-dots {
          margin-top: 5px;
        }
      }
      .owl-dots {
        .owl-dot {
          span {
            &::after {
              background: var(--whiteColor);
            }
          }
        }
      }
    }
  }
  .screen-swiper-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          width: 40px;
          left: -60px;
          height: 40px;
          font-size: 23px;

          &.owl-next {
            left: auto;
            right: -60px;
          }
        }
      }
    }
  }
  .testimonials-slides {
    .owl-theme {
      .owl-nav {
        text-align: center;
      }
    }
  }

  /*extra-css*/
  .partner-title {
    font-size: 15px;
  }
  .screenshots-tabs {
    .nav-tabset {
      .nav-tab {
        margin: {
          left: 3px;
          right: 3px;
        }
        span {
          font-size: 14px;
          padding: {
            top: 10px;
            left: 20px;
            right: 20px;
            bottom: 10px;
          }
        }
      }
    }
  }

  .new-feedback-wrap-slides {
    .owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          top: unset;
          bottom: 0;
          transform: unset;
          left: 0;
          transform: unset;
          margin: 0 10px;

          &.owl-next {
            left: auto;
            right: 0;
            transform: unset;
          }
        }
      }
    }
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shape6 {
    display: none;
  }
  .shape10 {
    left: -15px;
  }
  .shape11 {
    right: -15px;
  }
  .shape12 {
    bottom: 12%;
    left: 10%;
  }
  .banner-shape3,
  .banner-shape4 {
    display: none;
  }
  .banner-shape7,
  .banner-shape8 {
    display: none;
  }
  .banner-shape6 {
    right: 35%;
    top: 32%;
  }
  .banner-shape10 {
    right: 0;
  }
  .section-title {
    &.title-with-bg-text {
      .big-title {
        font-size: 70px;
        top: -15px;
      }
    }
  }
  .new-feedback-wrap-slides {
    .owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          top: unset;
          bottom: 0;
          transform: unset;
          left: 0;
          transform: unset;
          margin: 0 10px;

          &.owl-next {
            left: auto;
            right: 0;
            transform: unset;
          }
        }
      }
    }
  }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    &.title-with-bg-text {
      .big-title {
        font-size: 70px;
        top: -15px;
      }
    }
  }
}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {
  .banner-shape3 {
    bottom: 33%;
    left: 55%;
  }
  .banner-shape4 {
    bottom: 50%;
    right: 14%;
  }
  .banner-shape5 {
    right: 15%;
  }
  .banner-shape10 {
    right: 5%;
  }
}

.default-btn-2 {
  z-index: 1;
  border: none;
  position: relative;
  padding: 12px 35px;
  border-radius: 30px;
  display: inline-block;
  color: var(--whiteColor);
  transition: var(--transition);
  font: {
    size: var(--fontSize);
    weight: 600;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    transition: var(--transition);
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 30px;
    background: var(--blackColor);
  }
  &:hover {
    background-color: dodgerblue;
    color: var(--whiteColor);

    &::before {
      opacity: 0;
      visibility: hidden;
    }
  }
}
